import legals from './legals';

const soundTabs = {
  all: 'All',
  music: 'Music',
  asmr: 'ASMR',
  sound: 'Sound',
};
const validations = {
  required: 'Required',
  email: 'Email is not valid',
  fieldIsRequired: '{name} is required',
  fieldIsWrong: '{name} is wrong',
  fieldIsNotValid: '{name} is not valid',
  minLength: 'Minimum length is {length} symbols',
  maxLength: 'Maximum length is {length} symbols',
  pleaseSelectAnswer: 'Please select an answer',
};
const messages = {
  postsNotFound: 'No posts found',
  somethingWentWrong: 'Something went wrong',
  yourReportHasBeenSent:
    'Your report has been submitted and will be reviewed. Report ID - {reportId}',
  nothingFound: 'Nothing found',
  banOrUnbanUser: '{action} user',
  emergencySupportHTML: `
		Do not hesitate to seek professional assistance in case of a
		crisis or life-threatening situation. Call your
		<b
			><u
				><a
					href="https://www.google.com/search?q=local+emergency+service"
					target="_blank"
					>local emergency service</a
				></u
			></b
		>
		to get immediate help.
	`,
  emergencySupportBannerPriorityHTML: `
	Our platform cannot guarantee that you receive professional help in case
	of a crisis or a life-threatening situation. Please reach out to your
	<b
		><u
			><a
				href="https://www.google.com/search?q=local+emergency+service"
				target="_blank"
				>local emergency service</a
			></u
		></b
	>
	or immediately contact a licensed therapist. Your well-being is our top
	priority!	
	`,
  smartSearchFoundThis: 'Smart search found this',
  addStoriesBannerMessageHTML: `
	<p>
		We believe that telling your mental health story is a powerful
		way to help others who may be struggling with the same issues.
	</p>
	<p>
		By sending us your video, you can help break the stigma
		surrounding mental health and show others that they are not
		alone.
	</p>
	<p>
		Your story could be the one that offers hope, comfort, and
		inspiration to those who need it the most. So please don't be
		afraid to speak up – your voice matters!
	</p>
	`,
  youHaveNotPosted: `It seems that you haven't posted anything yet`,
  youHaveNotCommented: `It seems that you haven't left any comments yet`,
  commentsNotFound: 'No comments found',
  userNotFound: 'User not found',
  invalidEmailOrPassword: 'Invalid email or password',
  emailAlreadyInUse: 'Email already in use',
  yourPostHasBeenCreated: 'Your post has been created',
  coursesNotFound: 'No courses found',
  authSessionExpired: 'Auth session expired, please sign in again',
};
const modals = {
  deletePhotoTitle: 'Are you sure you want delete photo?',
  logoutTitle: 'Are you sure you want to logout?',
  deleteAccountTitle: 'Delete account?',
  deleteAccountText:
    'Are you sure you want to permanently delete your profile?',
  deleteCourseTitle: 'Are you sure you want to delete this course?',
  deleteCourseText: 'Deleting it will erase all your progress and results.',
  expertAdvicePaymentTitle:
    'Would you like to get advice from a licensed specialist?',
  expertAdvicePaymentHTMLText: `
      <li>The specialist provides you with a professional and detailed response.</li>
      <li>Our team consists of skilled specialists with extensive practical expertise.</li>
      <li>Your response will be tailored to your specific situation based on your post.</li>
      <li>You can remain anonymous and not disclose any personal information.</li>
		`,
  chooseLanguageTitle: 'Choose language',
  chooseLanguageText: 'You can change your preferred language in settings',
  emergencyHelpTitle: 'Attention!',
  emergencyHelpText: `If you find yourself in a life-threatening situation and need urgent
	help, please reach out to an emergency support service.`,
  emergencyHelpSupportBtn: 'Emergency Support',
  emergencyHelpSmallText: 'Find the nearest Support Service in Google',
  emergencyHelpRejectBtn: `Don't need urgent help`,
  rateCourseTitle: 'You have completed the course',
  rateCourseText: 'Please take a moment to give your feedback on the course.',
  rateCourseThankForFeedback: 'Thank you for your feedback!',
  rateCourseShareYourReview: 'Please share your review on',
  rateCourseAfterFinishText: 'How are you feeling after finishing the course?',
  notStartedCourseText: 'To watch the video, you must start the course.',
  tutorialCourseTitle: '4 Tips Before You Start',
  tutorialCourseTextItems: [
    'Start with theory and then move to practice. You can re-watch all videos upon completion.',
    'Have a pen and notebook ready.',
    'Set a specific time to avoid procrastination.',
    `Remember that quitting halfway won't lead to the desired results.`,
  ],
  requestNotificationsTitle: 'Stay connected with our community!',
  requestNotificationsText:
    'Let us notify you when specialists and other users reply to your posts.',
  requestNotificationsTextSmall: 'Press “next” to enable notifications.',
  watchedVideoTitle: 'You have watched the video',
  watchedVideoText: 'How do you feel at this moment?',
  appFeedbackQuestionTitle: 'Do you like FeelYou?',
  appFeedbackQuestionText:
    'Your feedback is crucial in attracting other users who require our help and support.',
  appFeedbackResponseTitle: 'Thank you for the feedback',
  appFeedbackNegativeResponseText:
    "We'll do our best to make FeelYou better for you. Feel free to contact our support team and help us improve your experience.",
  appFeedbackPositiveResponseText:
    'We’re happy to hear that you like FeelYou. Please take a moment to rate our app and share your feedback.',
  unavailableVideo: `We have organized the videos in this course in a specific order to provide
	you with the best possible help. This video will only be accessible after
	you have viewed the preceding ones.`,
  donateTitle: 'Your donation matters!',
  donateDescription: `Join the fight for mental health accessibility and save lives. Let’s make sure that no one is left alone and without the support they deserve.`,
  donateSupportBtn: 'Support the project',
  donateSupportHint: 'Fundraising on the Donorbox platform',
  donateListTitle: 'With your contribution we can continue:',
  donateListItems: [
    'Attract licensed specialists',
    'Provide affordable therapy tools',
    'Involve more volunteers',
    'Collect and share helpful content',
  ],
  postPurchaseInfoTitle: 'Post NOW to get: ',
  postPurchaseInfoItems: [
    '<b>Fast moderation:</b> quick access to community support',
    '<b>Advice from a specialist:</b> professional and detailed response to your post',
    '<b>Dedicated assistance</b> from volunteers',
  ],
  postPurchaseInfoBuyBtn: 'Post for $3.99',
  postPurchaseInfoCancelBtn: 'Later',
  postPurchaseDelayInfoTitle: 'Delay posting:',
  postPurchaseDelayInfoItems: [
    '<b>18 hours</b> moderation',
    '<b>NO advice</b> from a licensed therapist',
    '<b>Delayed support</b> from the community',
    '<b>Minimal support</b> from volunteers',
  ],
  postPurchasePostponeBtn: 'Postpone my post',
  deletePostTitle: 'Delete post',
  deletePostText: 'Are you sure you want to delete this post?',
  deletePostConfirmBtn: 'Yes',
  deletePostCancelBtn: 'No',
};
const time = {
  year: 'y',
  day: 'd',
  hour: 'h',
  minute: 'm',
  second: 's',
};
const weekdays = {
  monday: 'M',
  tuesday: 'T',
  wednesday: 'W',
  thursday: 'T',
  friday: 'F',
  saturday: 'S',
  sunday: 'S',
};
const moodItems = {
  terrible: 'terrible',
  bad: 'bad',
  okay: 'okay',
  good: 'good',
  excellent: 'excellent',
};
const emotionItems = {
  tired: 'tired',
  content: 'content',
  confused: 'confused',
  grateful: 'grateful',
  motivated: 'motivated',
  anxious: 'anxious',
  relaxed: 'relaxed',
  stressed: 'stressed',
  sad: 'sad',
  proud: 'proud',
  unmotivated: 'unmotivated',
  annoyed: 'annoyed',
  loving: 'loving',
  happy: 'happy',
  worried: 'worried',
  excited: 'excited',
  angry: 'angry',
  hopeless: 'hopeless',
  panicking: 'panicking',
  exhausted: 'exhausted',
};
const sleepItems = {
  poor: 'poor',
  okay: 'okay',
  good: 'good',
  awesome: 'awesome',
};
const symptomItems = {
  panicAttack: 'Panic attack',
  constantSadness: 'Constant sadness',
  moodSwings: 'Mood swings',
  selfHarmingThoughts: 'Self-harming thoughts',
  isolation: 'Isolation',
  thinkingCircles: 'Thinking circles',
  excessiveEmotions: 'Excessive emotions',
  lowAppetite: 'Low/high appetite',
  procrastination: 'Procrastination',
  anxiousThoughts: 'Anxious thoughts',
  fear: 'Fear',
  healthConcerns: 'Health concerns',
};

const onboardingItems = [
  {
    heading: 'Welcome to FeelYou community!',
    text: 'Take a quick tour to see how we can help you.',
    buttons: {
      primary: {
        text: 'Great, let’s go!',
      },
      secondary: {
        text: 'No, thanks',
      },
    },
  },
  {
    text: 'Tell your story anonymously to get community support and advice from therapists.',
    buttons: {
      primary: {
        text: 'Share my story',
      },
      secondary: {
        text: 'Next',
      },
    },
  },
  {
    text: 'Explore therapy tools: video courses, tests, breathing practices, and sound relaxation.',
    buttons: {
      primary: {
        text: 'Practice therapy exercises',
      },
      secondary: {
        text: 'Next',
      },
    },
  },
  {
    text: 'Get step-by-step support when emotions overwhelm you.',
    buttons: {
      primary: {
        text: 'Receieve instant help',
      },
      secondary: {
        text: 'Next',
      },
    },
  },
  {
    heading: "You're on the right path towards better mental health.",
    text: "Remember, you're not alone!",
    buttons: {
      primary: {
        text: 'Get started',
      },
    },
  },
];

const authFeatureList = [
  'Explore personalized feed',
  'Make posts & join discussions',
  `Get comments with therapist's advice`,
  'Access self-therapy videos',
  'Keep therapy & mood diary',
];

const tutorialItems = {
  feed: {
    title: 'This is the <b>Feed</b>',
    text: 'here our users share their stories, post comments and get advice from licensed specialists.',
  },
  discover: {
    title: 'In the <b>Discover</b>',
    text: 'you can explore any topic that interests you and access all the data and tools related to that topic.',
  },
  therapy: {
    title: 'The <b>Therapy</b>',
    text: 'features courses with practical exercises, audio relaxation tools, and fast-acting techniques.',
  },
  help: {
    title: 'In the <b>Help</b>',
    text: 'you receive help «here and now» when emotions become overwhelming and problems seem unsolvable.',
  },
  stories: {
    title: 'This is the <b>Stories</b>',
    text: 'where our users freely talk about their mental health and how they cope with their challenges.',
  },
  myProfile: {
    title: 'In the <b>Profile</b>',
    text: 'you can easily track your posts, comments, favorites, and achievements.',
  },
};
const notificationItems = {
  commentOnPost: 'Someone left a comment on Your post',
  replyOnComment: 'Someone answered on Your comment',
  specialistCommentOnPost: 'Specialist left a comment on Your post',
  newCommentOnParticipatedPost:
    'New comments appeared on the post you left a comment on',
  favedPost: 'New comments appeared on the post you marked as favorite',
  postponedCheckToPostAuthor: `Hey there! You posted on FeelYou 30 days ago. Swing by your post and share how you're feeling today`,
};

export default {
  validations,
  messages,
  modals,
  time,
  weekdays,
  moodItems,
  emotionItems,
  sleepItems,
  symptomItems,
  onboardingItems,
  authFeatureList,
  tutorialItems,
  notificationItems,
  soundTabs,
  legals: legals.en,
  send: 'Send',
  feed: 'Feed',
  therapy: 'Therapy',
  help: 'Help',
  stories: 'Stories',
  profile: 'Profile',
  onboarding: 'Onboarding',
  auth: 'Auth',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  category: 'Category',
  categories: 'Categories',
  coursesOnThisTopic: 'Courses on this topic',
  discussionOnThisTopic: 'Discussion on this topic',
  discover: 'Discover',
  pickTopicsThatInterestYou: 'Pick topics that interest you',
  search: 'Search',
  findSimilarStories: 'Find similar stories',
  speakOut: 'Speak Out',
  tellYourStoryAndGetHelp: 'Tell your story and get help',
  emergencySupport: 'Emergency support',
  personalTasks: 'Personal tasks',
  dailyCheck: 'Daily Check',
  seeOtherCourses: 'See other courses',
  finishedCourses: 'Finished Courses',
  quickCalmingTools: 'Quick calming tools',
  quickCalmingDescription:
    'Fast-acting exercises to calm down your mind and body',
  tests: 'Tests',
  testsDescription:
    'Useful psychological screening tests to help you learn more about yourself',
  seeOtherTests: 'See other tests',
  breathingPractices: 'Breathing practices',
  breathingPracticesDescription:
    'Several easy breathing techniques that may help you feel calmer and more relaxed',
  soundTherapy: 'Sound therapy',
  soundTherapyDescription:
    'Peaceful sounds for relaxation and finding your zen',
  today: 'Today',
  shareYourStory: 'Share your story!',
  storiesOfCelebrites: 'Stories of celebrities',
  comingSoon: 'Coming Soon',
  celebritesStoryDescription: `From anxiety to bipolar disorser, celebrities have faced it all. Watch their stories and find inspiration in their strength.`,
  storiesFromOurUsers: 'Stories from our users',
  storiesFromOurUsersDescription: `Discover how our users overcame their obstacles and found paths to recovery.`,
  all: 'All',
  specialist: 'Specialist',
  verifiedByFeelyou: 'Verified by Feelyou',
  noDescription: `It seems that there's no description here yet`,
  noFavorites: `It seems that you haven't added any favorites yet`,
  posts: 'Posts',
  comments: 'Comments',
  achievements: 'Achievements',
  favorites: 'Favorites',
  aboutMe: 'About Me',
  editProfile: 'Edit profile',
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  cancel: 'Cancel',
  deleteAccount: 'Delete account',
  logout: 'Logout',
  changeEmail: 'Change email',
  changePassword: 'Change password',
  changeName: 'Change name',
  editPhoto: 'Edit photo',
  save: 'Save',
  name: 'Name',
  oldPassword: 'Old password',
  newPassword: 'New password',
  password: 'Password',
  email: 'Email',
  newEmail: 'New email',
  share: 'Share',
  getExpertAdvice: 'Get expert advice',
  specialistAnswer: 'Specialist answer',
  edited: 'Edited',
  seeMore: 'See more',
  ago: 'ago',
  userBanned: 'User banned',
  explore: 'Courses',
  popularCourses: 'Popular courses',
  newCourses: 'New courses',
  searchForCoureses: 'Search for courses',
  mostPopular: 'Most popular',
  allTests: 'All tests',
  yourNotes: 'Your notes',
  mood: 'Mood',
  emotions: 'Emotions',
  sleep: 'Sleep',
  symptoms: 'Symptoms',
  apply: 'Apply',
  update: 'Update',
  theoryLessons: 'no theory lessons | 1 theory lesson | {n} theory lessons',
  practicalExercises:
    'no practical exercises | 1 practical exercise | {n} practical exercises',
  perDay: 'per day',
  firstAidKitVideos:
    'no first aid kit videos | 1 first aid kit video | {n} first aid kit videos',
  firstAidKitVideo: 'First Aid Kit',
  exercise: 'Exercise',
  video: 'Video',
  turnOnVideoSound: 'Turn on the video sound',
  continueWithoutSound: 'Continue video without sound',
  selectAToppic: 'Select a topic',
  createPost: 'Create post',
  createPostHelpText: `To get the best possible help and support from our community
	we'd love to hear your story with as much detail as you're comfortable sharing.`,
  youAreBlocked: 'You are blocked',
  okay: 'Okay',
  title: 'Title',
  message: 'Message',
  copyLink: 'Copy link',
  copied: 'Copied',
  shareToApps: 'Share to apps',
  delete: 'Delete',
  edit: 'Edit',
  hide: 'Hide',
  show: 'Show',
  unblock: 'Unblock',
  report: 'Report',
  comment: 'Comment',
  ban: 'Ban',
  unban: 'Unban',
  user: 'User',
  yourNote: 'Your note, write something',
  success: 'Success!',
  yourPostIsCreated: 'Your post is created',
  thereAreCoursesInThisProblem: 'There are courses in this problem',
  seePostsWithSimilarProblems: 'See posts with similar problems',
  postCreated: 'Post created',
  shareYourThoughtsInAPost: 'Please share your thoughts and concerns in a post',
  helpSpeakOutListHTML: `
		<li>
			Licensed specialists provide you with professional and
			detailed advice.
		</li>
		<li>
			Our community is always here to help and support you.
		</li>
	`,
  helpSearchText: `Find comfort and hope in the stories from our users, who
	have faced similar struggles.`,
  notifications: 'Notifications',
  intelligentSearch: 'Intelligent Search',
  aiSearchTitle: 'Struggling to find the right answer?',
  aiSearchMessage:
    'Turn your search query into the post and get the support you deserve.',
  convert: 'Convert',
  examples: 'Examples',
  recommendedTopics: 'Recommended topics',
  aiSearchDisclaimer: `If you’re not ready to share your concerns just yet, write down a few
	phrases about your worries and our intelligent search will find similar
	stories that match your issues.`,
  aiSearchPlaceholderItems: [
    'New level of extreme panic attack need help',
    'I often find myself trying the most random things to help calm myself',
    "I've been diagnosed with high functioning schizophrenia recently",
  ],
  changeLanguage: 'Change language',
  about: 'About',
  becomeAVolunteer: 'Become a volunteer',
  support: 'Support',
  userFAQ: 'User FAQ',
  legal: 'Legal',
  typeComment: 'Type a comment',
  post: 'Post',
  videoStories: 'Video stories from our users',
  addStoriesFooterText:
    'Send us an email, and we can discuss all the&nbsp;details and provide any assistance you may need',
  emailUs: 'E-mail us',
  therapistAdvice: 'Therapist advice',
  expertAdvicePaymentTitle: 'How to receive your personal advice?',
  expertAdvicePaymentInfo:
    'By paying a fee, you will receive a priority response from our specialist.',
  payNow: 'Pay now',
  weSupportBannerPeople: 'people',
  receivedOurSupport: 'have received our support {when}',
  helpUsGrow: 'Help us grow',
  rateOurApp: 'rate our app',
  andShareYourThoughts: 'and share your thoughts',
  yesterday: 'Yesterday',
  weSupportedDonateTitle: `Help us save somebody's life`,
  weSupportedDonateDescription: `Donate to <b>FeelYou</b> and help us bring more mental health tools to everyone.`,
  reply: 'Reply',
  level: 'Level',
  test: 'Test',
  questionsCount: '0 questions | 1 question | {n} questions',
  testBenefitsTitle: 'What you get: ',
  relatedCourses: 'Related courses',
  disclaimer: 'Disclaimer',
  testDisclaimer: `
		<p>
		The purpose of this test is to help you
		learn more about yourself and understand your feelings better.
		</p>
		<p>
			The test does not provide medical diagnoses. If you require
			professional and immediate assistance, please contact your local
			emergency service or a licensed therapist.
		</p>
	`,
  getStarted: 'Get started',
  skip: 'Skip',
  next: 'Next',
  final: 'Final',
  finish: 'Finish',
  restartTest: 'Restart test',
  or: 'or',
  beginNow: 'Begin now',
  course: 'Course',
  letsStart: 'Let’s start',
  continue: 'Continue',
  relatedToolsTitle: 'Related tools',
  relatedToolsDescription:
    'Additional materials that can help you in resolving your issue more effectively.',
  theoryLessonsTitle: 'Theory lessons',
  practicalExercisesTitle: 'Practical exercises',
  courseDisclaimerItems: [
    `The purpose of this course is to help you learn
		more about the topic and understand your feelings better. It is not
		a substitute for medical treatment and cannot provide medical
		diagnoses. If you require professional assistance, please contact
		your local emergency service or a therapist.`,
    `This course has been created and compiled by a verified licensed
		psychologist. The materials have been carefully prepared, and to
		present them, we have partially used frames generated by artificial
		intelligence.`,
  ],
  signupNow: 'Sign Up Now',
  authButtonsText: 'Do You have an account?',
  generate: 'Generate',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  DMCA: 'DMCA',
  agreeToOurPolicy: 'By clicking Sign Up, you agree to our',
  forgotPassword: 'Forgot Password',
  hello: 'Hello',
  nextVideo: 'Next video',
  returnToToday: 'Return to today',
  reviewLater: 'Later',
  contactUs: 'Contact Us',
  leaveReview: 'Leave Review',
  yourResult: 'Your Result',
  finished: 'Finished',
  inProgress: 'In progress',
  popularCoursesBarBannerHTML: `Our <span>free therapy courses</span> to {name}`,
  seeResults: 'See Results',
  yourTestResult: 'Your score: {x} out of {y}',
  postNow: 'Post Now',
  waitingForPublishBanner:
    'Waiting your turn. Your post will go live in {time} hours.',
  postPaymentRequested:
    'Payment processing.  Your post will go live once the payment is confirmed.',
  testResultsTopicBannerTitle: 'Want to work on this issue?',
  testResultsTopicBannerText:
    'Explore valuable resources from licensed therapists to dive deeper into this topic.',
  testResultsRelatedCourses: 'Start from these courses',
  author: 'Author',
  showMoreReplies: 'Show more replies',
  therapySections: {
    courses: {
      title: 'Video courses',
      description: 'Full-fledged programs from therapists.',
      bannerTitle: 'Theory & practice',
    },
    tests: {
      title: 'Tests',
      description: 'Psychological screening tests.',
      bannerTitle: 'Tests & advice',
    },
    quickCalming: {
      title: 'Quick calming tools',
      description: 'Techniques to release negative emotions.',
      bannerTitle: 'CBT tools',
    },
    breathingPractices: {
      title: 'Breathing practices',
      description: 'Exercises for calming and relaxation. ',
      bannerTitle: 'Breathe in... breathe out ',
    },
    soundTherapy: {
      title: 'Sound therapy',
      description: 'Peaceful audio for  finding your zen.',
      bannerTitle: 'Music, sound & ASMR',
    },
    visalization: {
      title: 'Visualization',
      description: 'Calming videos to help shift your focus.',
      bannerTitle: 'Visual meditation',
    },
  },
  quickCalmingListTabs: {
    all: 'All',
    conflict: 'Conflict',
    panic: 'Panic',
    stress: 'Stress',
    negativeThought: 'Negative thoughts',
    anger: 'Anger',
    pain: 'Pain',
    anxiety: 'Anxiety',
    fallAsleep: 'Fall asleep',
    fear: 'Fear',
    panickAtacks: 'Panic attack',
    tension: 'Tension',
  },
};
